
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-form-item label prop="id">
          <el-input v-model="formModel.id" placeholder="请输入" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label prop="createBy">
          <el-input v-model="formModel.createBy" placeholder="请输入" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label prop="createTime">
          <el-input v-model="formModel.createTime" placeholder="请输入" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label prop="updateBy">
          <el-input v-model="formModel.updateBy" placeholder="请输入" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label prop="updateTime">
          <el-input v-model="formModel.updateTime" placeholder="请输入" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="是否删除" prop="delFlag">
          <el-input v-model="formModel.delFlag" placeholder="请输入是否删除" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="申请车位ID" prop="parkingId">
          <el-input v-model="formModel.parkingId" placeholder="请输入申请车位ID" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="申请人ID" prop="ownerId">
          <el-input v-model="formModel.ownerId" placeholder="请输入申请人ID" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="申请类型" prop="contractType">
          <el-input v-model="formModel.contractType" placeholder="请输入申请类型" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="租赁时长(月)" prop="contractDuration">
          <el-input
            v-model="formModel.contractDuration"
            placeholder="请输入租赁时长(月)"
            style="width:300px"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import parkingApplyApi from "@/api/base/parkingApply";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        id: [{ required: true, message: "不能为空", trigger: "blur" }],
        createBy: [{ required: true, message: "不能为空", trigger: "blur" }],
        createTime: [{ required: true, message: "不能为空", trigger: "blur" }],
        updateBy: [{ required: true, message: "不能为空", trigger: "blur" }],
        updateTime: [{ required: true, message: "不能为空", trigger: "blur" }],
        delFlag: [
          { required: true, message: "是否删除不能为空", trigger: "blur" }
        ],
        parkingId: [
          { required: true, message: "申请车位ID不能为空", trigger: "blur" }
        ],
        ownerId: [
          { required: true, message: "申请人ID不能为空", trigger: "blur" }
        ],
        contractType: [
          { required: true, message: "申请类型不能为空", trigger: "blur" }
        ],
        contractDuration: [
          { required: true, message: "租赁时长(月)不能为空", trigger: "blur" }
        ]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {}
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return parkingApplyApi.add(self.formModel);
            } else {
              return parkingApplyApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              this.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              this.$emit("close", false);
            }
          });
        }
      });
    }
  },
  mounted: function() {
    var self = this;

    (function() {
      if (self.businessKey.length == 0) {
        return parkingApplyApi.create();
      } else {
        return parkingApplyApi.edit(self.menuId);
      }
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  }
};
</script>